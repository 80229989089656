@import url(https://fonts.googleapis.com/css?family=Lora:400,700);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
  opacity: 0.5;
}

.vertical-timeline-element-content {
  font-family: "Raleway";
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 5px 0px, rgba(0, 0, 0, 0.1) 0px 3px 1px -2px;
  border-radius: 15px;
}

.vertical-timeline::before {
  width: 3px;
  left: 18.5px;
}

.vertical-timeline::after {
  content: "";
  position: absolute;
  left: 14px;
  bottom: -6px;
  width: 12px;
  height: 12px;
  border-radius: 25px;
}

.vertical-timeline-element-icon {
  box-shadow: none;
}

.vertical-timeline-element-title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  font-family: "Montserrat";
  color: #585858;
  text-align: left;
  font-weight: 700;
  padding: 4px 12px;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
}

.timeline-date {
  font-size: 12px;
  font-weight: 400;
}

/*
.vertical-timeline-element-title h5.ui.header{
  font-family: 'Raleway';
}
*/

.vertical-timeline {
  padding-top: 0;
  padding-bottom: 40px !important;
}

.vertical-timeline-element-content p {
  font-size: 14px;
  color: #575757;
  text-align: left;
  margin-top: 7.5px;
}

.memorialCardContainer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 250px;
}

.memorialName {
  font-size: 20px;
}
.memorialDates {
  font-size: 14px;
}

.memorialHeaderContainer {
  display: flex;
  align-items: center;
  min-height: 86px;
}

.memorialImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.memorialImage {
  min-width: 86px;
  max-width: 86px;
  min-height: 86px;
  max-height: 86px;
  object-fit: cover;
  border-radius: 86px;
}

.stream {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.videoPlayerContainer {
  width: 100%;
}

.videoPlayer {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
}

.cardButtonFloater {
  font-family: Raleway !important;
  color: #fff !important;
  border-radius: 9px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  z-index: 9 !important;
  cursor: pointer !important;
}

.memorialButton {
  font-family: Raleway !important;
  color: #fff !important;
  border-radius: 9px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-right: 0px !important;
  min-height: 41px !important;
  margin: 20px 15px 0 !important;
  display: none !important;
}

.memorialButtonHide {
  font-family: Raleway !important;
  color: #fff !important;
  border-radius: 9px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer;
  min-height: 41px !important;
}

.memorialButtonVideo {
  font-family: Raleway !important;
  color: #fff !important;
  border-radius: 9px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-right: 0px !important;
  min-height: 41px !important;
  margin: 20px 15px 0 !important;
}

.memorialButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.twitter-picker {
  margin-top: 20px !important;
  width: 100% !important;
}

.twitter-picker input {
  margin-left: 5px !important;
  height: 11px !important;
}

.vertical-timeline {
  margin-top: 1.5em !important;
  margin-bottom: 3em !important;
}

.textAlignLeft {
  text-align: left;
}

i.whiteIcon {
  color: #fff !important;
  margin-right: 10px !important;
}

.facebookColumn {
  box-shadow: none !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline::after {
    left: 50%;
    margin-left: -6.5px;
  }
  .vertical-timeline-element--work.vertical-timeline-element:first-of-type::before {
    left: 50%;
    margin-left: -12px;
  }
  .memorialCardContainer {
    min-width: 40%;
    margin-top: 100px;
  }
  .memorialName {
    text-align: center;
    font-size: 28px;
  }
  .memorialDates {
    text-align: center;
    font-size: 18px;
  }
  .memorialHeaderContainer {
    flex-direction: column;
    justify-content: center;
  }
  .memorialImageContainer {
    margin-top: -100px;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .memorialImage {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 150px;
  }
  .memorialButton {
    display: inline-block !important;
  }
}

span.country-name {
  color: black;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
#root {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #72c9e9;
}

.ui.button > .icon:not(.button) {
  opacity: 1;
}

i.icon {
  margin: 0.25em !important;
  color: #575757;
}

.ui.menu {
  margin: 0px !important;
}

.public-label {
  text-align: left;
  font-weight: 600;
  color: #2e2343;
  margin-bottom: 2px;
}

.ui.item.simple.dropdown i {
  font-size: 20px;
}

.ui.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.ui.card > .content:after,
.ui.cards > .card > .content:after {
  display: none;
}

.errors {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: left !important;
}

.image-preview {
  padding: 10px;
  border-radius: 4px;
  background-color: #efefef;
  border: 1px solid #bbb;
}

.ui.small.modal {
  width: 100% !important;
}

.ui.small.modal.transition.visible.active .content {
  font-size: 1.5em !important;
}

.toastify__body {
  color: #111;
  font-size: 1.2em !important;
}

.message-container {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.ui.modal > .close {
  font-size: 2.25em !important;
  top: -3rem !important;
  right: -3rem !important;
}

/* File Upload */

.image-preview {
  margin-bottom: 15px !important;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  background: #3183db;
  border-radius: 3px;
  padding: 0.5em;
  color: #fff !important;
  margin-bottom: 15px !important;
  border-radius: 9px;
}

.fileContainer.fileContainerSmall {
  width: 175px;
}

.fileContainer [type='file'] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.fileContainer [type='file'] {
  cursor: pointer;
}

i.icon.fileContainerIcon {
  color: #fff;
  margin-right: 5px !important;
}

.ui.basic.modal > .close {
  color: #fff !important;
}

.ui.menu {
  border-radius: 0px !important;
}

.image-preview .ui.labeled.icon.button {
  margin-top: 10px !important;
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ui.basic.modal > .close {
    top: 0 !important;
    right: 1rem !important;
    color: #333;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: none;
}

@font-face {
  font-family: 'Bradford LL';
  src: local('Bradford LL'), url(/static/media/BradfordLL-Light.01fbe49e.otf) format('opentype');
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(/static/media/Matter-Regular.db62482e.otf) format('opentype');
}

@font-face {
  font-family: 'Steinskrift Valle';
  src: local('Steinskrift Valle'), url(/static/media/SteinskriftValle-Regular.927b1e56.otf) format('opentype');
}
