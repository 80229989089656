@import url('https://fonts.googleapis.com/css?family=Lora:400,700');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
#root {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #72c9e9;
}

.ui.button > .icon:not(.button) {
  opacity: 1;
}

i.icon {
  margin: 0.25em !important;
  color: #575757;
}

.ui.menu {
  margin: 0px !important;
}

.public-label {
  text-align: left;
  font-weight: 600;
  color: #2e2343;
  margin-bottom: 2px;
}

.ui.item.simple.dropdown i {
  font-size: 20px;
}

.ui.card {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.ui.card > .content:after,
.ui.cards > .card > .content:after {
  display: none;
}

.errors {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: left !important;
}

.image-preview {
  padding: 10px;
  border-radius: 4px;
  background-color: #efefef;
  border: 1px solid #bbb;
}

.ui.small.modal {
  width: 100% !important;
}

.ui.small.modal.transition.visible.active .content {
  font-size: 1.5em !important;
}

.toastify__body {
  color: #111;
  font-size: 1.2em !important;
}

.message-container {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.ui.modal > .close {
  font-size: 2.25em !important;
  top: -3rem !important;
  right: -3rem !important;
}

/* File Upload */

.image-preview {
  margin-bottom: 15px !important;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  background: #3183db;
  border-radius: 3px;
  padding: 0.5em;
  color: #fff !important;
  margin-bottom: 15px !important;
  border-radius: 9px;
}

.fileContainer.fileContainerSmall {
  width: 175px;
}

.fileContainer [type='file'] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.fileContainer [type='file'] {
  cursor: pointer;
}

i.icon.fileContainerIcon {
  color: #fff;
  margin-right: 5px !important;
}

.ui.basic.modal > .close {
  color: #fff !important;
}

.ui.menu {
  border-radius: 0px !important;
}

.image-preview .ui.labeled.icon.button {
  margin-top: 10px !important;
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .ui.modal > .close {
    top: 0 !important;
    right: 1rem !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .ui.basic.modal > .close {
    top: 0 !important;
    right: 1rem !important;
    color: #333;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: none;
}

@font-face {
  font-family: 'Bradford LL';
  src: local('Bradford LL'), url(./fonts/BradfordLL-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Matter';
  src: local('Matter'), url(./fonts/Matter-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Steinskrift Valle';
  src: local('Steinskrift Valle'), url(./fonts/SteinskriftValle-Regular.otf) format('opentype');
}